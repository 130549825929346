import React from "react";

import { Box, Button, Typography } from "@mui/material";
import QRCode from "qrcode";

import BankIdVectorRgb from "shared/images/BankIdVectorRgb";

interface BankIdMobileLoginQrProps {
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  loginFailed: boolean;
  qrCode: string | null;
  userMessage: string;
}

const BankIdMobileLogin: React.FC<BankIdMobileLoginQrProps> = ({
  onBackClick,
  loginFailed,
  qrCode,
  userMessage,
}: BankIdMobileLoginQrProps) => {
  const qrCodeCanvasRef = React.useRef(null);

  React.useEffect(() => {
    if (qrCode === null || qrCodeCanvasRef?.current === null) {
      return;
    }
    QRCode.toCanvas(qrCodeCanvasRef.current, qrCode, {
      width: 212,
    });
  }, [qrCode, qrCodeCanvasRef]);

  return (
    <>
      <BankIdVectorRgb
        sx={{
          width: 135,
          height: 112,
          ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
          mr: "auto !important",
          display: "block",
        }}
      />
      {loginFailed ? (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {userMessage}
        </Typography>
      ) : (
        <Box
          component="canvas"
          id="qrcode"
          sx={{
            minHeight: 212,
            minWidth: 212,
            width: 212,
            height: 212,
            ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
            mr: "auto !important",
            display: "block",
            borderRadius: 6,
            borderStyle: "solid",
            borderColor: "#D1DFF1",
          }}
          ref={qrCodeCanvasRef}
          aria-label={"QR-kod för inloggning med BankID"}
        />
      )}
      <Button
        variant="outlined"
        fullWidth
        disableRipple
        color="secondary"
        sx={{
          display: {
            xs: "inline-flex",
            md: "none",
          },
          mt: 0.5,
          borderColor: "#D1DFF1",
          borderRadius: 6,
          textDecoration: "none",
          height: 48,
          fontWeight: 700,
          fontSize: 14,
          lineHeight: "16px",
          letterSpacing: 1,
          textUnderlineOffset: 4,
          color: "secondary.main",
          "&:hover": {
            borderColor: "#D1DFF1",
            textDecoration: "none",
            color: "secondary.main",
            backgroundColor: "#FFFFFF",
            boxShadow: "unset",
          },
        }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
      <Button
        variant="text"
        fullWidth
        disableRipple
        sx={{
          display: {
            xs: "none",
            md: "inline-flex",
          },
          mt: 0.5,
          borderRadius: 6,
          textDecoration: "underline",
          height: 48,
          fontWeight: 700,
          fontSize: 14,
          lineHeight: "16px",
          letterSpacing: 1,
          textUnderlineOffset: 4,
          color: "primary.main",
          "&:hover": {
            textDecoration: "underline",
            color: "#FFFFFF",
            backgroundColor: "secondary.main",
          },
        }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
    </>
  );
};

export default BankIdMobileLogin;
