import React, { MutableRefObject } from "react";

import { Button, Typography } from "@mui/material";

import BankIdVectorRgb from "shared/images/BankIdVectorRgb";

interface BankIdThisDeviceLoginProps {
  bankIdOpened: boolean;
  loginFailed: boolean;
  setBankIdOpened: (bankIdOpened: boolean) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  startBankIdLogin: () => Promise<void>;
  bankIdUrl: MutableRefObject<string>;
  userMessage: string;
}

const BankIdThisDeviceLogin: React.FC<BankIdThisDeviceLoginProps> = ({
  bankIdOpened,
  loginFailed,
  onBackClick,
  setBankIdOpened,
  startBankIdLogin,
  bankIdUrl,
  userMessage,
}: BankIdThisDeviceLoginProps) => {
  const onOpenBankIdClick = async (
    _event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    await startBankIdLogin();
    setBankIdOpened(true);
    window.location.assign(bankIdUrl.current);
  };

  return (
    <>
      <BankIdVectorRgb
        sx={{
          width: 135,
          height: 112,
          ml: "auto !important", // for some reason, Stack's margin will overwrite these
          mr: "auto !important",
          display: "block",
        }}
      />
      {!loginFailed &&
        (bankIdOpened ? (
          <Button
            variant="contained"
            fullWidth
            disabled
            disableRipple
            sx={{
              mt: 0.5,
              borderRadius: 6,
              height: 48,
              fontWeight: 700,
              fontSize: 14,
              lineHeight: "16px",
              letterSpacing: 1,
              textTransform: "none",
              "&:disabled": {
                backgroundColor: "rgba(13, 48, 80, 0.5)",
                color: "#FFFFFF",
                boxShadow: "unset",
              },
            }}
          >
            Väntar på BankID
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              fullWidth
              onClick={onOpenBankIdClick}
              disableRipple
              sx={{
                mt: 0.5,
                borderRadius: 6,
                height: 48,
                fontWeight: 700,
                fontSize: 14,
                lineHeight: "16px",
                letterSpacing: 1,
                textTransform: "none",
                boxShadow: "unset",
                "&:hover": {
                  color: "#FFFFFF",
                  boxShadow: "unset",
                },
              }}
            >
              Öppna BankID på denna enhet
            </Button>
          </>
        ))}
      {loginFailed && (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {userMessage}
        </Typography>
      )}
      <Button
        fullWidth
        disableRipple
        variant="outlined"
        color="secondary"
        sx={{
          display: {
            xs: "inline-flex",
            md: "none",
          },
          mt: 0.5,
          borderColor: "#D1DFF1",
          borderRadius: 6,
          textDecoration: "none",
          height: 48,
          fontWeight: 700,
          fontSize: 14,
          lineHeight: "16px",
          letterSpacing: 1,
          textUnderlineOffset: 4,
          backgroundColor: "#FFFFFF",
          color: "secondary.main",
          "&:hover": {
            borderColor: "#D1DFF1",
            textDecoration: "none",
            color: "secondary.main",
            backgroundColor: "#FFFFFF",
            boxShadow: "unset",
          },
        }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
      <Button
        fullWidth
        disableRipple
        variant="text"
        color="primary"
        sx={{
          display: {
            xs: "none",
            md: "inline-flex",
          },
          mt: 0.5,
          borderRadius: 6,
          textDecoration: "underline",
          height: 48,
          fontWeight: 700,
          fontSize: 14,
          lineHeight: "16px",
          letterSpacing: 1,
          textUnderlineOffset: 4,
          color: "provider.main",
          "&:hover": {
            textDecoration: "underline",
            color: "#FFFFFF",
            backgroundColor: "secondary.main",
          },
        }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
    </>
  );
};

export default BankIdThisDeviceLogin;
